/*-----------------------------------------------------------------------------------*/
/*	8.  Slider
/*-----------------------------------------------------------------------------------*/
.slider {}

.flexslider {
    margin: 0;
    padding: 0;
}

.flexslider a {
    border: none;
}

.flexslider .slides>li {
    display: none;
    -webkit-backface-visibility: hidden;
    /* Hide the slides before the JS is loaded. Avoids image jumping */
    position: relative;
}

.flexslider .slides img {
    display: block;
    width: 100%;
}

.flex-pauseplay span {
    text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides>li:first-child {
    display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
    margin: 0 auto;
    position: relative;
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
    margin: 0;
}

.carousel li {
    margin-right: 5px;
}

/* Direction Nav */
.flex-direction-nav {
    height: 0;
    margin: 0;
    width: 0;
}

.flex-direction-nav a {
    width: 45px;
    height: 60px;
    margin: -20px 0 0;
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    text-indent: -9999px;
    -webkit-transition: all .3s ease;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.flex-direction-nav a:hover {
    background-color: rgba(0, 0, 0, 0.7);
    width: 45px;
    height: 60px;
}

.flex-direction-nav .flex-next {
    background-position: -38px 12px;
    right: -50px;
    -webkit-box-shadow: inset 3px 0 4px 2px rgba(0, 0, 0, 0.07);
    box-shadow: inset 3px 0 4px 2px rgba(0, 0, 0, 0.07);
}

.flex-direction-nav .flex-prev {
    left: -50px;
    -webkit-box-shadow: inset -3px 0 4px 2px rgba(0, 0, 0, 0.07);
    box-shadow: inset -3px 0 4px 2px rgba(0, 0, 0, 0.07);
}

.flexslider:hover .flex-next {
    opacity: 1;
    right: -5px;
}

.flexslider:hover .flex-prev {
    opacity: 1;
    left: -5px;
}

.flexslider .flex-next:hover {
    right: -2px;
}

.flexslider .flex-prev:hover {
    left: -2px;
}

.flex-direction-nav .disabled {
    opacity: .3 !important;
    filter: alpha(opacity=30);
    cursor: default;
}

.flex-direction-nav a:before {
    font-family: 'FontAwesome';
    font-size: 40px;
    display: inline-block;
    content: '\f104';
    color: #fff;
    left: 35%;
    position: absolute;
    text-indent: 0;
    top: 35%;
}

.flex-direction-nav a.flex-next:before {
    content: '\f105';
}

.flex-control-nav {
    bottom: 4%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 999;
}

.flex-control-nav li {
    margin: 0 3px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.flex-control-paging li a {
    width: 12px;
    height: 11px;
    display: block;
    background: #222;
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    opacity: .5 !important;
    filter: alpha(opacity=50);
}

.flex-control-paging li a:hover {
    background: #ddd;
    opacity: 1 !important;
    filter: alpha(opacity=100);
}

.flex-control-paging li a.flex-active {
    background: #f0f0f0;
    cursor: default;
    position: relative;
}

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
}

.flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
}

.flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: .7;
    cursor: pointer;
}

.flex-control-thumbs img:hover {
    opacity: 1;
}

.flex-control-thumbs .active {
    opacity: 1;
    cursor: default;
}

.flexslider .slides li:first-child:nth-last-child(1) .flex-caption,
.flexslider .slides li:only-child .flex-caption {
    display: block;
}

.flex-caption {
    position: absolute;
    left: 0;
    padding: 15px 20px 0 0;
    max-width: 50%;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.fade-effect .flex-caption {
    display: none;
}

.flex-caption h2,
.flex-caption p {
    text-align: left;
}

.slide-info {
    left: 120px;
    padding-top: 160px;
    position: absolute;
    top: 0;
    width: 100%;
}

.flex-caption h2 {
    font-size: 38px;
    line-height: 48px;
    padding: 0 15px 15px 0;
    color: #fff;
}

.flex-caption p {
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    margin: 0 0 10px;
    padding: 10px 10px 10px 0;
}

.flex-caption p a {
    border-bottom: 1px solid #3e7ec2;
    color: #fff;
}

.flex-active-slide .flex-caption {
    display: block;
}

.carousel li {
    margin-right: 30px;
}

#flex-carousel .flex-direction-nav {
    bottom: auto;
    left: auto;
}

#flex-carousel .flex-direction-nav a {
    width: 40px;
    height: 80px;
    margin: 0;
    display: block;
    background-image: url(assets/img/slide/bg_direction_nav.png);
    background-repeat: no-repeat;
    background-color: transparent;
    position: absolute;
    top: 40%;
    cursor: pointer;
    text-indent: -9999px;
    opacity: 0;
    -webkit-transition: all .3s ease;
    box-shadow: none;
}

#flex-carousel .flex-direction-nav .flex-next {
    background-position: -35px -40px;
    right: -30px;
    left: auto;
}

#flex-carousel .flex-direction-nav .flex-prev {
    background-position: 0 -40px;
    left: -30px;
}

#flex-carousel .flex-direction-nav li {
    margin: 0;
    padding: 0;
}

#home-staff:hover .flex-next {
    opacity: 1;
    right: -80px;
}

#home-staff:hover .flex-prev {
    opacity: 1;
    left: -80px;
}